import React from "react"
import useTranslations from "../../utils/useTranslations"
import ComparisonPanels from "../../components/comparisonPanels"
import LocalizedLink from "../../components/localizedLink"
import Logo from "../../components/logo"
import { KasperskyHook } from "../../components/kasperskyHook"

const BittorrentClassicCompare = ({ pageContext: {text, pageName, locale} }) => {
const t = useTranslations(text)

let {isKO, isTR, kasperskyBanner} = KasperskyHook(locale)

const logoTitle = <>
	<div className="font-weight-normal text-nowrap">Compare <div className="compare-page-logo-container"><Logo fsCoefficient="17" fsMax="27" secondaryColor="black" tag="p" productName="Classic" className="compare-page-logo"/></div> Versions</div>
</>
const titleContent = (locale === "en") ? logoTitle : t("Compare µTorrent Classic Versions")
  return (
  	<div className="mt-5 pt-5 panel-bg">
	    <div className="container">
		    <h1 className="h2 text-center mb-5">{ titleContent }</h1>
	      {
	      	kasperskyBanner
	      }
	      <ComparisonPanels pageContext={{ "text":text, "pageName":pageName, "isKasperskyTR": isTR, "isKasperskyKO": isKO }}>
			    <p className="text-center mt-5">
			      	<LocalizedLink to="/pro/" id="products-win-classic-compare-premium-learnmore" className="font-weight-bold">
			      		{t(`Learn more`)}
			      	</LocalizedLink> {t(`about µTorrent Classic premium products`)}
				</p>
	      </ComparisonPanels>
	    </div>
	</div>
  )
}

export default BittorrentClassicCompare